<div class="vr">
    <div class="vr">
        <div class="hdg hdg_2">Sign In</div>
        {{#if content}}<p class="bdcpy">{{removeMarkup content}}</p>{{/if}}
    </div>

    <form class="sfForm js-element--signInForm" action="">
        <div class="sfForm-row">
            <div class="sfForm-row-item isRequired{{#if errors.username}} sfForm-row-item_hasErrors{{/if}}" id="userName">
                <label class="sfForm-lbl" for="username">Username</label>
                <input class="sfForm-input js-element--signInUsername" value="{{formValues.username}}" name="username" id="username" type="text">
                <div class="sfForm-msg sfForm-msg_error">{{errors.username}}</div>
            </div>
        </div>
        <div class="sfForm-row">
            <div class="sfForm-row-item isRequired{{#if errors.password}} sfForm-row-item_hasErrors{{/if}}" id="password">
                <label class="sfForm-lbl" for="password">Password</label>
                <input class="sfForm-input js-element--signInPassword" value="{{formValues.password}}" name="password" id="password" type="password">
                <div class="sfForm-msg sfForm-msg_error">{{errors.password}}</div>
            </div>
        </div>
        <div class="sfForm-row">
            <div class="sfForm-row-item">
                <button class="btn btn_cta mix-btn_fullWidth">Sign In</button>
            </div>
        </div>
    </form>
    <div>
        <a class="link" href="javascript:passwordPanel.open()">Forgot Password?</a>
    </div>
</div>
