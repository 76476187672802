// import RegisterFormController from "../components/register/RegisterFormController";
import SignInFormController from "../components/signIn/signInFormController";

const signInFormContainer = document.querySelector('.js-element--signInContainer');
// const registerFormContainer = document.querySelector('.js-element--registerFormContainer');

new SignInFormController(signInFormContainer, onSucessCallBack);
// new RegisterFormController(registerFormContainer);

function onSucessCallBack () {
    window.location.assign('/');
}