import signInForm from './signInForm.hbs';
import generateErrorHandler from '../error/errorResponseHandler';
import contentBlockService from '../contentBlock/contentBlockService/contentBlockService';
import userService from '../_services/userService/userService';

const SIGNIN_FORM_ELEMENT = '.js-element--signInForm';
const SIGNIN_USERNAME_ELEMENT = '.js-element--signInUsername'
const SIGNIN_PASSWORD_ELEMENT = '.js-element--signInPassword'

const formValues = {
    username: '',
    password: '',
};

class SignInFormController {
    constructor (container, onSucessCallBack) {
        this.state = {
            errors: {},
            formValues: Object.assign({}, formValues),
            content: '',
        };

        this.container = container;
        this.template = signInForm;
        this.onSucessCallBack = onSucessCallBack;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);

        this.enableUI();
        this.getContentBlock();
    }

    getContentBlock () {
        contentBlockService.getBlock('signin-form-message')
            .then( (content) => {
                this.state.content = content;
            })
            .then(this.renderForm);
    }

    enableUI () {
        this.container.addEventListener('submit', this.handleSubmit, false);
    }

    renderForm () {
        this.container.innerHTML = this.template(this.state);
    }

    handleSubmit (e) {
        if (e.target.matches(SIGNIN_FORM_ELEMENT)) {
            e.preventDefault();
            this.handleSignIn();
            return;
        }
    }

    clearFormValues () {
        this.state.formValues = Object.assign({}, formValues);
        this.state.errors = {};
    }

    handleSignIn () {
        this.state.formValues.username = this.container.querySelector(SIGNIN_USERNAME_ELEMENT).value;
        this.state.formValues.password = this.container.querySelector(SIGNIN_PASSWORD_ELEMENT).value;

        const loginOptions = {
            username: this.state.formValues.username,
            password: this.state.formValues.password,
        };

        this.setValid();
        this.renderForm();

        userService.login(loginOptions)
            .then(this.onSucessCallBack)
            .catch(generateErrorHandler({
                list: e => {
                    e.errors.forEach(error => this.state.errors[error.field] = error.message);
                },
            }))
            .finally(this.renderForm);
    }

    setValid () {
        this.state.errors = {};
    }
}
export default SignInFormController;